import { type Router } from 'vue-router'

import { type ChipData } from '@/components/TagChip.type'
import { type ArtworkType } from '@/graphql/generated_villus'
import { isArtworkType } from '@/utils/common'

import { sentry } from './sentry'

let router: Router | null = null

export enum QueryString {
  /**
   * 主要給 artiest page 跟 gallery page 導頁用
   */
  ArtworkType = 'artwork_type',
  /**
   * 為了雲端發票存在的 query string
   */
  CarrierBarcodeImageUrl = 'carrier_barcode_image_url',
  Design = 'design',
  Device = 'device',
  DeviceColor = 'device_color',
  /**
   * 折扣碼
   */
  DiscountCode = 'discount_code',
  /**
   * 使用外部圖片
   */
  ForeignPhotoUrl = 'foreign_photo_url',
  /**
   * 關鍵字搜尋
   */
  Keyword = 'keyword',
  Language = 'language',
  Product = 'product',
  ProductColor = 'product_color',
  ProductNavigation = 'product_navigation',
  /**
   * 分享設計用的，value 帶 uuid，會從後端拉回設計的 config 倒入到 local storage 裡面
   */
  ShareDesign = 'share_design',
  /**
   * gallery page 分享 tags
   */
  Tags = 'tags',
}
function isQueryString(key: unknown): key is QueryString {
  const keys: string[] = Object.values(QueryString)
  return typeof key === 'string' && keys.includes(key)
}

export interface DesignQuery {
  id: string
  type: 'background' | 'collage' | 'sticker'
}

function isDesignType(type: string): type is DesignQuery['type'] {
  return ['background', 'collage', 'sticker'].includes(type)
}

export function getDesignQuery(query: string): DesignQuery | null {
  const sepIndex = query.indexOf('_')
  const type = query.slice(0, Math.max(0, sepIndex))
  const id = query.slice(sepIndex + 1)
  if (!isDesignType(type)) {
    return null
  }
  return { type, id }
}

// TODO: 現階段是在一開始 @/router.ts update 他，之後做個 store 來記錄 cookie 跟 query string
export function updateUseRouter(_router: Router): void {
  router = _router
}

export async function hasValidShareDesign(): Promise<boolean> {
  if (router === null) {
    return false
  }
  await router.isReady()
  const urlQuery = getUrlQueryString()
  if (
    urlQuery.has(QueryString.ShareDesign) &&
    (!urlQuery.has(QueryString.ProductNavigation) || !urlQuery.has(QueryString.Product))
  ) {
    await setQueryStringShareDesignUuid(null)
    return false
  }
  return true
}

function getUrlQueryString(): Map<QueryString, string> {
  // 因為 router 可能會還沒準備好就要拉資料了
  // 而且 isReady 是 async function 我不想讓 get query 變成 async function
  // 所以目前我先監聽 router 裡面的 query 是不是空的，如果是空的 object 就會去用 window.location.search 檢查是不是真的沒有 query string
  const queryObject: Array<[string, string]> =
    router !== null && Object.values(router.currentRoute.value.query).length !== 0
      ? Object.entries(router.currentRoute.value.query).filter(
          (item): item is [string, string] => typeof item[1] === 'string',
        )
      : [...new URLSearchParams(window.location.search).entries()]
  const queryString = new Map<QueryString, string>()
  for (const [key, value] of queryObject) {
    if (isQueryString(key)) {
      queryString.set(key, value)
    }
  }
  return queryString
}

let urlQuery: Map<QueryString, string> | null = null
async function updateQueryString(queryString: QueryString, value: null | string): Promise<void> {
  if (router === null) {
    return
  }
  await router.isReady()

  if (urlQuery === null) {
    urlQuery = getUrlQueryString()
  }
  if (value === null) {
    urlQuery.delete(queryString)
  } else {
    urlQuery.set(queryString, value)
  }
  await router
    .replace({
      query: Object.fromEntries(urlQuery.entries()),
      force: true,
    })
    .catch((error: unknown) => {
      sentry.error(`router replace query ${queryString} to ${value ?? 'null'} failed`, {
        urlQuery,
        error,
      })
    })
}

export function getQueryStringDeviceHandle(): null | string {
  return getUrlQueryString().get(QueryString.Device) ?? null
}

export async function setQueryStringDeviceHandle(device: string): Promise<void> {
  await updateQueryString(QueryString.Device, device)
}

export function getQueryStringDeviceColor(): null | string {
  return getUrlQueryString().get(QueryString.DeviceColor) ?? null
}

export async function setQueryStringDeviceColor(deviceColor: string): Promise<void> {
  await updateQueryString(QueryString.DeviceColor, deviceColor)
}

export function getQueryStringProductHandle(): null | string {
  return getUrlQueryString().get(QueryString.Product) ?? null
}

export async function setQueryStringProductHandle(product: string): Promise<void> {
  await updateQueryString(QueryString.Product, product)
}

export function getQueryStringProductColor(): null | string {
  return getUrlQueryString().get(QueryString.ProductColor) ?? null
}

export async function setQueryStringProductColor(productColor: string): Promise<void> {
  await updateQueryString(QueryString.ProductColor, productColor)
}

export function getQueryStringProductNavigation(): null | string {
  return getUrlQueryString().get(QueryString.ProductNavigation) ?? null
}

export async function setQueryStringProductNavigation(productColor: string): Promise<void> {
  await updateQueryString(QueryString.ProductNavigation, productColor)
}

export async function setQueryStringDesign(design: string): Promise<void> {
  await updateQueryString(QueryString.Design, design)
}

export function getQueryStringLanguage(): null | string {
  return getUrlQueryString().get(QueryString.Language) ?? null
}

export function getQueryStringDiscountCode(): null | string {
  return getUrlQueryString().get(QueryString.DiscountCode) ?? null
}

export async function setQueryStringDiscountCode(discountCode: null | string): Promise<void> {
  await updateQueryString(QueryString.DiscountCode, discountCode)
}

export function getQueryStringCarrierBarcodeImageUrl(): null | string {
  return getUrlQueryString().get(QueryString.CarrierBarcodeImageUrl) ?? null
}

export async function setQueryStringCarrierBarcodeImageUrl(
  carrierBarcodeImageUrl: null | string,
): Promise<void> {
  await updateQueryString(QueryString.CarrierBarcodeImageUrl, carrierBarcodeImageUrl)
}

export function getQueryStringShareDesignUuid(): null | string {
  return getUrlQueryString().get(QueryString.ShareDesign) ?? null
}

export async function setQueryStringShareDesignUuid(uuid: null | string): Promise<void> {
  await updateQueryString(QueryString.ShareDesign, uuid)
}

export function getQueryStringArtworkType(): ArtworkType | null {
  const type = getUrlQueryString().get(QueryString.ArtworkType)
  return isArtworkType(type) ? type : null
}

export async function setQueryStringArtworkType(type: ArtworkType): Promise<void> {
  await updateQueryString(QueryString.ArtworkType, type)
}

export async function setQueryStringTags(tagList: Map<string, ChipData>): Promise<void> {
  const keyArray: string[] = []
  tagList.forEach((tag) => keyArray.push(tag.key))
  await updateQueryString(QueryString.Tags, keyArray.toString())
}

export function getQueryStringTags(): null | string[] {
  return getUrlQueryString().get(QueryString.Tags)?.split(',') ?? null
}

export function getQueryStringKeyword(): string {
  const keyword = getUrlQueryString().get(QueryString.Keyword)
  return typeof keyword === 'string' ? decodeURIComponent(keyword) : ''
}

export async function setQueryStringKeyword(keyword: null | string): Promise<void> {
  await updateQueryString(
    QueryString.Keyword,
    typeof keyword === 'string' ? encodeURIComponent(keyword) : null,
  )
}

export function getQueryStringForeignPhotoUrl(): null | string {
  return getUrlQueryString().get(QueryString.ForeignPhotoUrl) ?? null
}

export async function clearQueryStringForeignPhotoUrl(): Promise<void> {
  await updateQueryString(QueryString.ForeignPhotoUrl, null)
}
