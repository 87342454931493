import { setTag } from '@sentry/vue'

import { getQueryStringLanguage } from '@/utils/queryString'

export enum Locale {
  ASIA = 'en-ASIA',
  DE = 'de-DE',
  ES = 'es-ES',
  EU = 'en-EU',
  FR = 'fr-FR',
  IO = 'en',
  JP = 'ja-JP',
  KR = 'ko-KR',
  TH = 'th-TH',
  TW = 'zh-TW',
  UK = 'en-GB',
}

export interface ShopifyConfig {
  domainKey: string
  shopDomain: string
  shopifyDomain: string
  storefrontAccessToken: string
}

export const shopifyTokenList: Record<Locale, ShopifyConfig> = {
  [Locale.TW]: {
    domainKey: 'rhinoshieldtaiwan',
    shopDomain: 'https://rhinoshield.tw',
    shopifyDomain: 'rhinoshieldtaiwan.myshopify.com',
    storefrontAccessToken: '3246d7839bd95750cb9fb98a4bad8752',
  },
  [Locale.JP]: {
    domainKey: 'rhinoshieldjp',
    shopDomain: 'https://rhinoshield.jp',
    shopifyDomain: 'rhinoshieldjp.myshopify.com',
    storefrontAccessToken: '68d75f71edd417ccb532728de938dfb0',
  },
  [Locale.TH]: {
    domainKey: 'rhinoshieldth',
    shopDomain: 'https://rhinoshield.co.th',
    shopifyDomain: 'rhinoshieldth.myshopify.com',
    storefrontAccessToken: '7668e8da5812effafc0ad7eeb4b60ae3',
  },
  [Locale.ASIA]: {
    domainKey: 'rhinoshieldasia',
    shopDomain: 'https://rhinoshield.co',
    shopifyDomain: 'rhinoshieldasia.myshopify.com',
    storefrontAccessToken: 'dae84671a6fc6ff65199f48dd32e3c8d',
  },
  [Locale.IO]: {
    domainKey: 'rhinoshield',
    shopDomain: 'https://rhinoshield.io',
    shopifyDomain: 'rhinoshield.myshopify.com',
    storefrontAccessToken: 'e23fb526f1ee2780dcec8d40a634dc94',
  },
  [Locale.EU]: {
    domainKey: 'rhinoshieldeu',
    shopDomain: 'https://rhinoshield.eu',
    shopifyDomain: 'rhinoshieldeu.myshopify.com',
    storefrontAccessToken: 'cfb147ef05b5b442588251ad16c89d9f',
  },
  [Locale.UK]: {
    domainKey: 'rhinoshielduk',
    shopDomain: 'https://rhinoshield.uk',
    shopifyDomain: 'rhinoshielduk.myshopify.com',
    storefrontAccessToken: 'c85af2488d1b820ee901601c4d50c3b2',
  },
  [Locale.FR]: {
    domainKey: 'rhinoshieldfr',
    shopDomain: 'https://rhinoshield.fr',
    shopifyDomain: 'rhinoshieldfr.myshopify.com',
    storefrontAccessToken: 'e350bf8a35a65fbffaba7bcc86d0fa99',
  },
  [Locale.DE]: {
    domainKey: 'rhinoshieldde',
    shopDomain: 'https://rhinoshield.de',
    shopifyDomain: 'rhinoshieldde.myshopify.com',
    storefrontAccessToken: 'c6c7c61d74ddbe0c4309fbca5a9ca181',
  },
  [Locale.ES]: {
    domainKey: 'rhinoshieldes',
    shopDomain: 'https://rhinoshield.es',
    shopifyDomain: 'rhinoshieldes.myshopify.com',
    storefrontAccessToken: 'd02473a7faa3078275533966a2e50fe3',
  },
  [Locale.KR]: {
    domainKey: 'rhinoshieldkr',
    shopDomain: 'https://rhinoshield.kr',
    shopifyDomain: 'rhinoshieldkr.myshopify.com',
    storefrontAccessToken: 'ddb3e7df575fb151aab389ebd17c9991',
  },
}

const currencyUnitForLocale: Record<Locale, string> = {
  [Locale.TW]: 'TWD',
  [Locale.JP]: 'JPY',
  [Locale.TH]: 'THB',
  [Locale.ASIA]: 'USD',
  [Locale.IO]: 'USD',
  [Locale.EU]: 'EUR',
  [Locale.UK]: 'GBP',
  [Locale.FR]: 'EUR',
  [Locale.DE]: 'EUR',
  [Locale.ES]: 'EUR',
  [Locale.KR]: 'KRW',
}
export function currencyUnit(): string {
  const locale = getCurrentLocale()
  return currencyUnitForLocale[locale]
}

function isLocale(locale: unknown): locale is Locale {
  const locales: string[] = Object.values(Locale)
  return typeof locale === 'string' && locales.includes(locale)
}

const defaultLocale = Locale.TW
let currentLocale: Locale | null = null
export function getCurrentLocale(): Locale {
  if (currentLocale !== null) {
    return currentLocale
  }
  const { host: currentHost } = window.location
  const findLocale = Object.entries(shopifyTokenList).find(([, { shopDomain }]) => {
    const domain = shopDomain.replace(/https?:\/\//, '')
    return currentHost.endsWith(domain)
  })
  const locale = findLocale?.[0] ?? null
  currentLocale = isLocale(locale) ? locale : defaultLocale
  setTag('locale', currentLocale)
  return currentLocale
}

export const officialPage = shopifyTokenList[getCurrentLocale()].shopDomain

export enum HostEnv {
  Development = 'development',
  Production = 'production',
  Staging = 'staging',
}

export function getNodeENV(): HostEnv {
  const { host } = window.location
  if (host.includes('my.rhinoshield')) {
    return HostEnv.Production
  } else if (host.includes('my.dev.rhinoshield')) {
    return HostEnv.Staging
  }
  return HostEnv.Development
}

export function getCurrentIsProduction(): boolean {
  return getNodeENV() === HostEnv.Production
}

export function priceWithLocaleCurrencyUnit(price: number): string {
  const locale = getCurrentLocale()
  const priceWithLocaleCurrencyUnit = price.toLocaleString(locale, {
    currency: currencyUnit(),
    style: 'currency',
    minimumFractionDigits: 0,
  })
  return priceWithLocaleCurrencyUnit
}

let currentLanguage: Locale | null = null
export function getCurrentLanguage(): Locale {
  if (currentLanguage !== null) {
    return currentLanguage
  }
  const queryStringLng = getQueryStringLanguage()
  currentLanguage = isLocale(queryStringLng) ? queryStringLng : getCurrentLocale()
  return currentLanguage
}
